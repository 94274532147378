<template>
    <section class="welcome">
        <div class="welcome__info">
            <h2 class="welcome__title">Fastero do Brasil</h2>
            <p class="welcome__text">Bem-vindo à nossa empresa líder em produção e venda de produtos de alta qualidade através de infraestrutura tecnológica moderna para gerenciar vendas diretas</p>
        </div>
        <div class="welcome__picture">
            <img src="../assets/images/graph.png" alt="welcome" class="welcome__image"/>
            <transition name="slide">
                <img v-if="showWelcome" src="../assets/images/woman.png" alt="woman" class="welcome__woman"/>
            </transition>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                show: false
            }
        },
        props: {
            showWelcome: {
                default: false,
                type: Boolean,
            }
        }
    }
</script>

<style scoped>
    .welcome {
        background: url("../assets/images/welcome.jpg") #d099e9;
        background-size: cover;
        background-position: bottom center;
        align-items: end;
        padding: 77px;
    }
    .welcome__info, .welcome__picture {
        max-width: 550px;
        color: #ffffff;
    }
    .welcome__picture {
        position: relative;
    }
    .welcome__woman {
        position: absolute;
        left: 50%;
        max-width: 142.5px;
        bottom: 10px;
        height: 88%;
    }
    .welcome__title {
        font-size: 3.5rem;
        font-weight: 600;
        line-height: 1.5em;
        margin-bottom: 20px;
    }
    .welcome__text {
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1.5em;
    }
    .welcome__image {
        width: 100%;
    }
    .slide-enter-active {
        transition: all 0.3s ease-out;
    }
    .slide-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .slide-enter-from {
        transform: translateX(100px);
    }
    .slide-leave-to {
        transform: translateX(0px);
    }
</style>