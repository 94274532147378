<template>
    <section class="solutions">
        <transition name="slide">
            <div class="info" v-if="showSolutions">
                <div class="title">Soluções eficazes</div>
                <p class="text">Trabalhamos em estreita colaboração com o consumidor por meio de uma rede de call centers em todo o mundo. Isso nos permite identificar as necessidades de nosso público-alvo em tempo hábil e implementar as melhores estratégias para expandir segmentos de mercado selecionados</p>
            </div>
        </transition>
        <div class="wrapper">
            <img alt="glass" class = "picture" src="../assets/images/glass.jpg"/>
        </div>
    </section>
</template>

<script>

    export default {
        props: {
            showSolutions: {
                default: false,
                type: Boolean,
            }
        }
    }
</script>

<style scoped>
   .solutions {
       background-image: url('../assets/images/marketing.png');
   }
   .slide-enter-from {
       transform: translateX(-1000px);
   }
</style>