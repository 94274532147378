<template>
    <section class="marketing">
        <transition name="slide">
            <div class="info" v-if="showMarketing">
                <div class="title">Marketing e promoção</div>
                <p class="text">Para comercializar os produtos, compramos serviços de publicidade de alta qualidade (modelo CPA) onde o cliente tem o primeiro contato com a mercadoria, seu interesse é direcionado para as centrais de vendas de contato, onde os agentes de vendas levam o cliente a conhecer mais de perto os produtos, tendo e fazendo todas as perguntas necessárias sobre as mercadorias (forma de usar, ofertas especiais e outras), os pedidos confirmados são pick & pack nos centros de atendimento e processados ​​na entrega da última milha por COD</p>
            </div>
        </transition>
        <div class="wrapper">
            <img alt="target" class = "picture" src="../assets/images/target.jpg"/>
        </div>
    </section>
</template>

<script>

    export default {
        props: {
            showMarketing: {
                default: false,
                type: Boolean,
            }
        }
    }
</script>

<style scoped>
   .marketing {
       background-image: url('../assets/images/marketing.png');
   }
   .slide-enter-from {
       transform: translateX(-1000px);
   }
</style>