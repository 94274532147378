<template>
    <header>
        <div class="header__vidget">
            <div class="logo__wrapper">
                <img class="header__logo" alt="logo" src="../assets/images/logo.png"/>
            </div>
        </div>
        <Welcome :showWelcome="showWelcome"/>
    </header>

</template>

<script>
    import Welcome from '../components/welcome';
    export default {
        props: {
            showWelcome: {
                default: false,
                type: Boolean,
            }
        },
        components: {
            Welcome
        }
    }
</script>

<style scoped>
    .header__vidget {
        background: rgb(208, 153, 233);
        display: flex;
        align-items: center;
        padding: 20px 0 15px 0;
        display: flex;
        justify-content: center;
    }
    .logo__wrapper {
       width: 60%;
    }
    .header__logo {
        width: 59px;
    }
</style>