<template>
    <section class="environment">
        <h2 class="environment__title">Com cuidado com o meio ambiente</h2>
        <h2 class="environment__title">beleza e saúde</h2>
        <p class="environment__p">Produtos de alta qualidade não são palavras vazias. É muito importante para nós vender produtos</p>
        <p class="environment__p">comprovados por pessoas e tempo para melhorar a saúde do cliente.</p>
    </section>
</template>

<script>
    export default {

    }
</script>

<style scoped>
    .environment {
        background: linear-gradient(100deg, #d895e2 0%, #b8a7ff 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .environment__title {
        color: #1f2873;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.3em;
        text-align: center;
    }
    .environment__title:nth-child(2) {
        margin-bottom: 20px;
    }
    .environment__p {
        text-align: center;
        color: #1f2873;
        font-size: 0.9rem;
        line-height: 1em;
        margin-bottom: 1.75em;
    }
    .environment__p:nth-child(3) {
        margin-bottom: 1.75em;
    }
</style>