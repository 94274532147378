<template>
  <Header :showWelcome="showWelcome"/>
  <Advantages />
  <Infrastructure/>
  <Analysis :showAnalysis="showAnalysis"/>
  <Manufacture :showManufacture="showManufacture"/>
  <Marketing :showMarketing="showMarketing"/>
  <Optimization :showOptimization="showOptimization"/>
  <Solutions :showSolutions="showSolutions"/>
  <Environment/>
  <Principles/>
  <Team :showTeam="showTeam"/>
  <Footer/>
  <Arrow v-if="showLift"/>
</template>

<script>
  import Footer from './components/footer';
  import Header from './components/header';
  import Environment from './components/environment';
  import Solutions from './components/solutions';
  import Optimization from './components/optimization';
  import Marketing from './components/marketing';
  import Analysis from './components/analysis';
  import Manufacture from './components/manufacture';
  import Team from './components/team';
  import Principles from './components/principles';
  import Advantages from './components/advantages';
  import Infrastructure from "@/components/infrastructure";
  import Arrow from '@/components/arrow';


  export default {
    data() {
      return {
        showWelcome: false,
        showLift: false,
        showAnalysis: false,
        showManufacture: false,
        showMarketing: false,
        showOptimization: false,
        showSolutions: false,
        showTeam: false
      }
    },
    components: {
      Footer,
      Header,
      Environment,
      Optimization,
      Solutions,
      Marketing,
      Analysis,
      Manufacture,
      Principles,
      Team,
      Advantages,
      Infrastructure,
      Arrow,
    },
    methods: {
      changeShowLift() {
        if (window.scrollY > 500) {
          this.showLift = true
        } else {
          this.showLift = false;
        }
      },
      setAnimationWelcome() {
        if (window.scrollY > 0) {
          this.showWelcome = true
        } else if (window.scrollY <= 0) {
          this.showWelcome = false;
        }
      },
      setAnimationAnalysis() {
        if (window.scrollY > 800) {
          this.showAnalysis = true
        } else {
          this.showAnalysis = false;
        }
      },
      setAnimationManufacture() {
        if (window.scrollY > 1100) {
          this.showManufacture = true
        } else {
          this.showManufacture = false;
        }
      },
      setAnimationMarketing() {
        if (window.scrollY > 1400) {
          this.showMarketing = true
        } else {
          this.showMarketing = false;
        }
      },
      setAnimationOptimization() {
        if (window.scrollY > 1700) {
          this.showOptimization = true
        } else {
          this.showOptimization = false;
        }
      },
      setAnimationSolutions() {
        if (window.scrollY > 2000) {
          this.showSolutions = true
        } else {
          this.showSolutions = false;
        }
      },
      setAnimationTeam() {
        if (window.scrollY > 4300) {
          this.showTeam = true
        } else {
          this.showTeam = false;
        }
      },
      moveScroll(e) {
        this.changeShowLift();
        this.setAnimationWelcome();
        this.setAnimationAnalysis();
        this.setAnimationManufacture();
        this.setAnimationMarketing();
        this.setAnimationOptimization();
        this.setAnimationSolutions();
        this.setAnimationTeam();
      },
      observeMovingScroll() {
        window.addEventListener('scroll', this.moveScroll);
      },
    },
    mounted() {
      this.observeMovingScroll();
    },
    unmounted() {
      window.removeEventListener('scroll', this.moveScroll);
    }
  }
</script>

<style>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }
  html {
    scroll-behavior: smooth;
  }
  #app {
    position: relative;
  }
  section {
    padding: 100px 15px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .info {
    max-width: 380px;
  }
  .title {
    color: #1f2873;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.3em;
    margin-bottom: 20px;
    text-align: center;
  }
  .text {
    color: #1f2873;
    font-size: 0.9rem;
    line-height: 1.5em;
  }
  .wrapper {
    width: 652px;
    display: flex;
    justify-content: center;
  }
  .picture {
    width: 392px;
    max-width: 100%;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    max-width: 1100px;
  }
  .card {
    padding: 40px 40px 40px 40px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
    display: flex;
    max-width: 333px;
    gap: 20px;
    margin: 10px;
    color: #42417d;
    flex-wrap: wrap;
  }
  .card:hover {
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.18);
  }
  .card > img {
    width: 40px;
    height: 40px;
  }
  .card__title {
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1em;
  }
  .card__text {
    font-size: 0.8rem;
    line-height: 1.5em;
  }
  .slide-enter-active {
    transition: all 0.8s ease-out;
  }
  .slide-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-leave-to {
    transform: translateX(0px);
  }
  @font-face {
    font-family: "Poppins";
    src: url("./assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  }

  @media (max-width: 400px) {
    .title {
      font-size: 1.5rem;
    }
  }


</style>
