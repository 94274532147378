<template>
    <section class="optimization">
        <div class="wrapper">
            <img src="../assets/images/cars.png" alt="cars" class="picture"/>
        </div>
        <transition name="slide">
            <div class="info" v-if="showOptimization">
                <div class="title">Otimização da cadeia de suprimentos</div>
                <p class="text">Estamos constantemente desenvolvendo nossa base tecnológica para otimizar a logística e gerenciar todos os processos, desde a colocação do pedido na loja online até o recebimento da mercadoria pelo comprador</p>
            </div>
        </transition>

    </section>
</template>

<script>
    export default {
        props: {
            showOptimization: {
                default: false,
                type: Boolean,
            }
        }
    }
</script>

<style scoped>
    .optimization {
        background-image: url("../assets/images/optimization.png");
        background-color: #f9f7ff;
    }
    .slide-enter-from {
        transform: translateX(1000px);
    }
</style>