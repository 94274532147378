<template>
    <section class="optimization">
        <div class="wrapper">
            <img src="../assets/images/manufacture.png" alt="manufacture" class="picture"/>
        </div>
        <transition name="slide">
            <div class="info" v-if="showManufacture">
                <div class="title">Manufatura</div>
                <p class="text">A Fastero  exerce um controle cuidadoso sobre todas as etapas de produção, desenvolvimento e teste de produtos. Base de produção moderna, potencial científico desenvolvido e funcionários altamente qualificados nos permitem crescer e se desenvolver. Essa abordagem traz confiança ao fabricante, ao cliente e ao comprador</p>
            </div>
        </transition>
    </section>
</template>

<script>
    export default {
        props: {
            showManufacture: {
                default: false,
                type: Boolean,
            }
        }
    }
</script>

<style scoped>
    .optimization {
        background-image: url("../assets/images/optimization.png");
        background-color: #f9f7ff;
    }
    .slide-enter-from {
        transform: translateX(1000px);
    }
</style>