<template>
    <footer>
        <Contacts/>
        <div class="footer__copyright">
            Fastero do brasil © 2023
        </div>

    </footer>
</template>

<script>
    import Contacts from '../components/contacts';
    export default {
        components: {
            Contacts
        }
    }
</script>

<style scoped>
    .footer__copyright {
        color: #252525;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        background:  #B1B1B1;
        line-height: 1.25em;
        font-weight: bold;
    }
</style>