<template>
    <section class="infrastructure">
        <div class="card">
            <h2 class="title">Nós criamos infraestrutura  moderna para venda direta de cosméticos e suplementos alimentares. </h2>
        </div>
        <p class="text">Bem-vindo à nossa empresa, líder na fabricação e comercialização de suplementos alimentares e cosméticos 100% naturais. Com um compromisso inabalável com a saúde e o bem-estar, oferecemos uma ampla gama de produtos que ajudam a melhorar a qualidade de vida de nossos clientes.</p>
        <p class="text">A Fastero do Brasil  cria e desenvolve uma moderna infraestrutura para vendas diretas. Construímos  uma cadeia de interação com o cliente final que nos permite obter  produção e promoção de produtos da mais alta qualidade.</p>
    </section>
</template>

<script>
    export default {

    }
</script>

<style scoped>
    .infrastructure {
        padding: 20px;
        flex-direction: column;
    }
    .card {
        max-width: 85%;
    }
    .text {
        text-align: center;
    }
    .text:nth-child(2) {
        width: 86%;
        padding: 40px;
    }
    .text:nth-child(3) {
        width: 95%;
    }
</style>