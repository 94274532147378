<template>
    <section class="principles">
        <div class="title">Principais recursos</div>
        <div class="cards">
            <div class="card">
                <img src="../assets/images/erp.png" alt="erp"/>
                <div class="card__info">
                    <h3 class="card__title">Sistema ERP interno</h3>
                    <div class="card__text">Produto interno da empresa para fornecer gerenciamento de processos de negócios e ferramentas de automação. A funcionalidade combina métodos de integração com sistemas de terceiros, parte financeira, trabalho para melhorar a eficiência da gestão de recursos, monitoramento e geração de relatórios.</div>
                </div>
            </div>
            <div class="card">
                <img src="../assets/images/crm.png" alt="crm"/>
                <div class="card__info">
                    <h3 class="card__title">Sistema de CRM interno</h3>
                    <div class="card__text">Um call center virtual que permite que os principais tipos de usuários interajam remotamente de forma eficaz. O sistema possibilita: recrutamento remoto, treinamento e interação com operadores e líderes de equipe, organização do escritório de auditoria, organização de painéis de controle para o call center gerencial, etc.</div>
                </div>
            </div>
            <div class="card">
                <img src="../assets/images/app.png" alt="app"/>
                <div class="card__info">
                    <h3 class="card__title">Aplicativo de entrega em casa</h3>
                    <div class="card__text">O aplicativo inclui fácil contratação de entregadores e logísticos, seu rápido envolvimento no trabalho, trabalho com o pedido, rastreamento do pedido, controle de caixa e cobrança.</div>
                </div>
            </div>
            <div class="card">
                <img src="../assets/images/innovation.png" alt="innovation"/>
                <div class="card__info">
                    <h3 class="card__title">Inovação em produtos</h3>
                    <div class="card__text">Com uma grande experiência em marketing apoiada por muitos anos de experiência de nossos funcionários, estamos sempre à frente da indústria.</div>
                </div>
            </div>
            <div class="card">
                <img src="../assets/images/platform.png" alt="platform"/>
                <div class="card__info">
                    <h3 class="card__title">Plataforma tecnológica</h3>
                    <div class="card__text">O elo central através do qual todos os sistemas e processos da empresa são gerenciados. Ele inclui todos os recursos do software existente e o transforma em um único sistema.</div>
                </div>
            </div>
            <div class="card">
                <img src="../assets/images/formula.png" alt="formula"/>
                <div class="card__info">
                    <h3 class="card__title">Desenvolvimento de Fórmula</h3>
                    <div class="card__text">Desenvolvemos produtos únicos com uma receita única, em colaboração com os melhores fabricantes de todo o mundo.</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {

    }
</script>

<style scoped>
    .principles {
        background-image: url("../assets/images/principles.png");
        flex-direction: column;
    }
</style>