<template>
    <section class="advantages">
        <h2 class="title">Por que a Fastero do Brasil</h2>
        <div class="cards">
            <div class="card">
                <img src="../assets/images/eye.png" alt="eye"/>
                <div class="card__info">
                    <h3 class="card__title">Visão</h3>
                    <div class="card__text">A visão da FASTERO no Brasil é ser reconhecida como a principal empresa de suplementos alimentares e cosméticos do país</div>
                </div>
            </div>
            <div class="card">
                <img src="../assets/images/aim.png" alt="aim"/>
                <div class="card__info">
                    <h3 class="card__title">Missão</h3>
                    <div class="card__text">Fornecer produtos de alta qualidade no ramo de suplementos alimentares e cosméticos</div>
                </div>
            </div>
            <div class="card">
                <img src="../assets/images/diamond.png" alt="diamond"/>
                <div class="card__info">
                    <h3 class="card__title">Valores</h3>
                    <div class="card__text">Os valores da FASTERO são fundamentais para a nossa atuação e norteiam todas as nossas ações e decisões</div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {

    }
</script>

<style scoped>
    .advantages {
        flex-direction: column;
        background: url("../assets/images/advantage.png") #ffffff;
        background-size: cover;
        background-position: top center;
        padding-bottom: 50px;
    }
    .cards {
        gap: 20px;
    }
    .card {
        flex-direction: column;
        align-items: center;
    }
    .card > img {
        width: 51px;
        height: 51px;
    }
    .card__title {
        text-align: center;
        padding-bottom: 10px;
    }
    .card__text {
        text-align: center;
    }
</style>