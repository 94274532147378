<template>
    <section class="contacts">
        <div class="title">Contate-nos</div>
        <p class="text">Se você tiver alguma dúvida ou sugestão, envie-nos um e-mail e responderemos o mais breve possível</p>
        <div class="contancts__address">
            <h2 class="contacts__title">Endereço</h2>
            <div class="contacts__item">
                <div class="contacts__icon">
                    <img src="../assets/images/house.svg" alt="house" />
                </div>
                Via Anhanguera S/N Galpao1 Modulo III Sl 20 Jardim Jaragua São Paulo SP 05275-000
            </div>
            <div class="contacts__item">
                <div class="contacts__icon">
                    <img src="../assets/images/envelopes.svg" alt="envelopes" />
                </div>
                info@fasterodobrasil.com
            </div>
        </div>
    </section>
</template>

<script>
    export default {
    }
</script>

<style scoped>
    .contacts {
        background-image: url('../assets/images/map.png');
        flex-direction: column;
    }
    .contancts__address {
        margin-top: 50px;
    }
    .contacts__title {
        font-size: 20px;
        font-weight: 600;
    }
    .contacts__item {
        display: flex;
        gap: 10px;
    }
    .contacts > * {
        color: #27445e;
    }
    .contacts__icon {
        width: 17px;
        display: flex;
        align-items: center;
    }
    .contacts__icon > img {
        width: 17px;
    }

</style>