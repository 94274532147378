<template>
    <div @click="scrollToUp" tabindex="0" class="scroll__top">
    <span class="scroll__icon"><svg  xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="57 35.171 26 16.043" enable-background="new 57 35.171 26 16.043" xml:space="preserve">
            <path fill="#ffffff" d="M57.5,38.193l12.5,12.5l12.5-12.5l-2.5-2.5l-10,10l-10-10L57.5,38.193z"></path>
            </svg>
    </span>
    </div>
</template>

<script>
    export default {
        methods: {
            scrollToUp() {
                window.scrollTo(0, 0);
            },
        }
    }
</script>

<style scoped>
    .scroll__top {
        background-color: #046bd2;
        font-size: 15px;
        font-size: 0.9375rem;
        width: 1.6rem;
        display: flex;
        align-items: center;
        height: 1.6rem;
        justify-content: center;
        cursor: pointer;
        position: fixed;
        right: 30px;
        bottom: 30px
    }
    .scroll__icon svg {
        margin-left: 0px;
        vertical-align: middle;
        transform: translate(0, -20%) rotate(180deg);
        width: 0.85em;
    }
</style>