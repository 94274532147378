<template>
    <section class="analysis">
        <transition name="slide">
            <div class="info" v-if="showAnalysis">
                <div class="title">Análise e Monitoramento</div>
                <p class="text">A Fastero realiza pesquisas de mercado, desenvolve estratégia de publicidade, assume a organização de processos de escritório e apoio na área de gestão de pessoas</p>
            </div>
        </transition>
        <div class="wrapper">
            <img alt="charts" class = "picture" src="../assets/images/charts.jpg"/>
        </div>
    </section>
</template>

<script>

    export default {
        props: {
            showAnalysis: {
                default: false,
                type: Boolean,
            }
        }
    }
</script>

<style scoped>
   .analysis {
       background-image: url('../assets/images/analysis.png');
   }
   .slide-enter-from {
       transform: translateX(-1000px);
   }
</style>