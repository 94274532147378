<template>
    <section class="team">
        <div class="team__top">
            <transition name="slide">
                <img src="../assets/images/beard.png" alt="beard" v-if="showTeam"/>
            </transition>
            <transition name="slide">
                <img src="../assets/images/right-top.png" alt="right-top" v-if="showTeam"/>
            </transition>
        </div>
        <div class="team__text">
            Juntos, nossa equipe de suporte técnico está comprometida em oferecer assistência profissional e confiável sempre que você precisar. Independentemente do desafio que você enfrentar, estaremos aqui para ajudar e garantir que você obtenha a melhor solução possível para suas necessidades.
        </div>
        <div class="team__middle">
            <img src="../assets/images/sofia.png" alt="sofia"/>
            <div>
                <div class="team__name">Sofia Change</div>
                <div class="team__job">Director @ virtualedu</div>
            </div>
        </div>
        <div class="team__bottom">
            <transition name="slide">
                <img src="../assets/images/left-bottom.png" alt="left-bottom" v-if="showTeam"/>
            </transition>
            <transition name="slide">
                <img src="../assets/images/right-bottom.png" alt="right-bottom" v-if="showTeam"/>
            </transition>
        </div>

    </section>
</template>

<script>
    export default {
        props: {
            showTeam: {
                default: false,
                type: Boolean,
            }
        }
    }
</script>

<style scoped>
    .team {
        background: url("../assets/images/team.png"), linear-gradient(100deg, #d895e2 0%, #b8a7ff 100%);
        background-position: center left;
        background-size: cover;
        padding: 100px 15px;
        flex-direction: column;
    }
    .team__top {
        display: flex;
        justify-content: space-between;
        width: 900px;
        max-width: 100%;
        padding-bottom: 50px;
    }
    .team__name {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.5;
    }
    .team__job {
        font-size: .85em;
    }
    .team__top > img, .team__bottom > img {
        width: 76px;
        height: 76px;
        border: 8px solid rgba(229,214,255,0.36);
        border-radius: 50%;
    }
    .team__middle {
        display: flex;
        gap: 20px;
        align-items: center;
    }
    .team__middle > * {
        color: #fff;
    }
    .team__middle > img {
        width: 60px;
        height: 60px;
        border: 8px solid rgba(229,214,255,0.36);
        border-radius: 50%;
    }
    .team__bottom {
        max-width: 1150px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .team__picture {
        width: 76px;
        height: 76px;
    }
    .team__text {
        color: #ffffff;
        font-size: 1.2rem;
        font-weight: 200;
        max-width: 960px;
        padding-bottom: 20px;
    }
    .slide-enter-from {
        transform: translateX(20px);
    }

</style>